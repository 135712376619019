import {backendApi} from "../../backend-api-sr"

export const KkaApproval = (() =>{
    const fetchMillFilter = ( async (subPath) => {
        var url = '/api/v3/kka/approval/filter-mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchBranchOfficeFilter = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/filter-branch-office'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const fetchGroupRight = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-group-right'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const fetchData = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-data'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const fetchDataDetail = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const fetchDataPo = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-data-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const fetchDataDetailPO = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-detail-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const getAprvLevel = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/get-aprv-level'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const approve = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/approve'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const approvePo = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/approve-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const unapprove = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/unapprove'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const unapprovePo = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/unapprove-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const reject = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/reject-item'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const rejectPo = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/reject-item-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const exportDetail = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/export-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const exportDetailPO = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/export-detail-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    const getInvBal = ( async (subPath, reqbody) => {
        var url = '/api/v3/kka/approval/invBal'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqbody, false, false, false)
    })

    return {getAprvLevel, fetchMillFilter, fetchBranchOfficeFilter, fetchGroupRight, fetchData, fetchDataDetail, fetchDataPo, fetchDataDetailPO, approve, approvePo, unapprove, unapprovePo, reject, rejectPo, exportDetail, exportDetailPO, getInvBal};
})()