<template>
    <v-card class="ma-0 pb-4 round_top_29" height="auto" outlined>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-card-text class="pb-12">
            <v-data-table dense :headers="headers" :items="data" class="elevation-1 mt-3" :items-per-page="30"
                height="450" fixed-header :divider="true" :light="true" 
                :search="searchHeader">
                <template v-slot:top>
                    <v-toolbar flat color="white" class="mt-2 mb-2">
                        <div class="d-flex w-100">
                            <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchHeader"
                                append-icon="mdi-magnify" label="Search" dense single-line
                                hide-details></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn small color="#fff" class="py-5 mr-3" @click="getData()">
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            <v-btn small color="#005c37" class="py-5 mr-3 text-white">
                                <v-icon>mdi-file-excel</v-icon>
                                <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                    :before-generate="startDownload" :before-finish="finishDownload">
                                    Export Excel
                                </download-excel>
                            </v-btn>
                        </div>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.dt_approve1`]="{ item }">
                    <div v-if="item.dt_approve1 != '1900-01-01'">
                        <v-chip class="ma-2" color="success" text-color="white" v-if="item.dt_approve1 != '1900-01-01' && item.dt_approve2 == '1900-01-01'" >
                            {{ item.dt_approve1 }}
                        </v-chip>
                        <v-chip class="ma-2" color="primary" text-color="white" v-else>
                            {{ item.dt_approve1 }}
                        </v-chip>
                    </div>
                </template>

                <template v-slot:[`item.dt_approve2`]="{ item }">
                    <div v-if="item.dt_approve2 === '1900-01-01'">
                        <v-chip class="ma-2" color="primary" v-if="item.dt_approve2 === '1900-01-01'" @click="setApprove(item)" >
                            <v-icon center> mdi-check-circle</v-icon>
                            Approve 2
                        </v-chip>
                        <v-chip class="ma-2" color="red" text-color="white" v-else>
                            Not Approved
                        </v-chip>
                    </div>
                    <div v-if="item.dt_approve2 != '1900-01-01'">
                        <v-chip class="ma-2" color="success" text-color="white" v-if="item.dt_approve2 != '1900-01-01'"  >
                            {{item.dt_approve2}}
                        </v-chip>
                    </div>
                </template>

                <!-- <template v-slot:[`item.status_aprv`]="{ item }">
                    <v-btn text color="red" v-if="item.dt_approve2 === '1900-01-01 00:00:00.000'">Not
                        Approved</v-btn>
                    <v-btn text color="success" v-else>{{ (new Date(new
                        Date(item.dt_approve2) - (new Date()).getTimezoneOffset() *
                        60000)).toISOString().substr(0, 10) }}</v-btn>
                </template> -->

                <template v-slot:[`item.action`]="{ item }">
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="grey" class="text-center">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item @click="showDetailItem(item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Detail</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item v-if="item.dt_approve2 === '1900-01-01 00:00:00.000' && aprvPR"
                                @click="showApproval(item, 'aprv')">
                                <v-list-item-icon>
                                    <v-icon>mdi-check</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Approve</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.dt_approve2 != '1900-01-01 00:00:00.000' && aprvPR"
                                @click="showApproval(item, 'unaprv')">
                                <v-list-item-icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>UnApprove</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card-text>
        <v-dialog v-model="dialogDetail" fullscreen persistent max-width="1200px" hide-overlay transition="dialog-bottom-transition">
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    {{ dialogPrId }}
                                    <!-- {{ detail ? detail.mill_name : '' }} # {{ detail ? detail.pr_id : '' }} -->
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn class="mx-2" fab x-small color="#e83e8c;" @click="[dialogDetail = false]">
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-data-table dense :headers="detail_header" :items="dataDetail" class="elevation-1 mt-3"
                        :items-per-page="30" :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }" :loading="$store.state.overlay" height="250" fixed-header :divider="true" :light="true"
                        :search="searchItem" :item-class="tr_datatable_outstanding" @click:row="showInvBal">
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <v-spacer></v-spacer>
                                    <div v-if="dialogDtApprovePo === '1900-01-01'">
                                
                                        <v-chip class="ma-2" color="primary" v-if="dialogDtApprovePo === '1900-01-01'" @click="setApproveDetail(item)">
                                            <v-icon center> mdi-check-circle</v-icon>
                                            Approve 2
                                        </v-chip>
                                        <v-chip class="ma-2" color="red" text-color="white" v-else>
                                            Not Approved
                                        </v-chip>
                                    </div>
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3"
                                        v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line
                                        hide-details></v-text-field>
                                </div>
                            </v-toolbar>
                        </template>

                        <template v-slot:[`item.stat`]="{ item }">
                            <div>
                                <v-chip class="ma-2" color="red" v-if="item.stat === 'O'" @click="rejectDialog(item)">
                                    <v-icon center> mdi-close</v-icon>
                                    Reject
                                </v-chip>
                                <v-chip class="ma-2" color="red" v-if="item.stat === 'X'">
                                    <v-icon center> mdi-check</v-icon>
                                    Rejected
                                </v-chip>
                                <v-chip class="ma-2" color="primary" v-if="item.stat != 'X' && item.stat != 'O' ">
                                    {{ item.stat }}
                                </v-chip>
                            </div>
                        </template>
                        <template v-slot:[`item.qty`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.qty) }}
                        </template>
                        <template v-slot:[`item.wgt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                        </template>
                        <template v-slot:[`item.qty_last`]="{ item }">
                            {{ item.qty_last ? $store.getters.convertToCurrencyNoFrac(item.qty_last) : '' }}
                        </template>
                        <template v-slot:[`item.unit_price_last`]="{ item }">
                            {{ item.unit_price_last ? $store.getters.convertToCurrencyUs(item.unit_price_last) : '' }}
                        </template>
                        <template v-slot:[`item.unit_price`]="{ item }">
                            {{ item.unit_price ? $store.getters.convertToCurrencyUs(item.unit_price) : '' }}
                        </template>
                        <template v-slot:[`item.total_qty_oh`]="{ item }">
                            {{ item.total_qty_oh ? $store.getters.convertToCurrencyUs(item.total_qty_oh) : '' }}
                        </template>
                        <template v-slot:[`item.dt_reqship`]="{ item }">
                            {{ item.dt_reqship != '1900-01-01 00:00:00.000' ? item.dt_reqship : '' }}
                        </template>
                    </v-data-table>
                    <v-data-table dense :headers="inv_bal_header" :items="inv_bals" class="elevation-1 mt-3"
                        :items-per-page="30" :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }" :loading="loading_inv_bal" height="250" fixed-header :divider="true" :light="true"
                        :search="searchItemInv" :item-class="tr_datatable">
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <h6>Qty Gudang in Office</h6>
                                    <v-spacer></v-spacer>
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3"
                                        v-model="searchItemInv" append-icon="mdi-magnify" label="Search" dense single-line
                                        hide-details></v-text-field>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.qty_awal`]="{ item }">
                            {{ item.qty_awal ? $store.getters.convertToCurrencyUs(item.qty_awal) : '' }}
                        </template>
                        <template v-slot:[`item.qty_oh`]="{ item }">
                            {{ item.qty_oh ? $store.getters.convertToCurrencyUs(item.qty_oh) : '' }}
                        </template>
                        <template v-slot:[`item.qty_rcv`]="{ item }">
                            {{ item.qty_rcv ? $store.getters.convertToCurrencyUs(item.qty_rcv) : '' }}
                        </template>
                        <template v-slot:[`item.qty_iss`]="{ item }">
                            {{ item.qty_iss ? $store.getters.convertToCurrencyUs(item.qty_iss) : '' }}
                        </template>
                        <template v-slot:[`item.qty_ia`]="{ item }">
                            {{ item.qty_ia ? $store.getters.convertToCurrencyUs(item.qty_ia) : '' }}
                        </template>
                        <template v-slot:[`item.wgt_awal`]="{ item }">
                            {{ item.wgt_awal ? $store.getters.convertToCurrencyUs(item.wgt_awal) : '' }}
                        </template>
                        <template v-slot:[`item.wgt_oh`]="{ item }">
                            {{ item.wgt_oh ? $store.getters.convertToCurrencyUs(item.wgt_oh) : '' }}
                        </template>
                        <template v-slot:[`item.wgt_rcv`]="{ item }">
                            {{ item.wgt_rcv ? $store.getters.convertToCurrencyUs(item.wgt_rcv) : '' }}
                        </template>
                        <template v-slot:[`item.wgt_iss`]="{ item }">
                            {{ item.wgt_iss ? $store.getters.convertToCurrencyUs(item.wgt_iss) : '' }}
                        </template>
                        <template v-slot:[`item.wgt_ia`]="{ item }">
                            {{ item.wgt_ia ? $store.getters.convertToCurrencyUs(item.wgt_ia) : '' }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogRejectMessage" persistent max-width="1200px" hide-overlay transition="dialog-bottom-transition">
            <v-card class="rounded-l">
                <v-card-text class="ma-0">
                    <h7 class="blue-lcd">Reject Note <strong style="color:red;">*</strong><span></span></h7>
                    <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="rejectNoteDialog" 
                        label="Reject Note" single-line hide-details>
                    </v-text-field>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" x-small color="#e83e8c;" @click="[dialogRejectMessage=false]">
                        Cancel
                    </v-btn>
                    <v-btn class="mx-2" x-small color="red" @click="rejectItem()">
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import ContentFooter from '../../../layouts/Footer.vue'
import VueCropper from 'vue-cropperjs'
import { KkaApproval } from "../../../backend-api/kka/approval/kka_approval"

export default {
    components: {
        ContentFooter, VueCropper
    },
    data() {
        return {
            trType: 'PO',
            aprvLevel:'2',
            appNum:'2',
            groupIds: [],
            officeIds: [],
            offGrps: [],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            branchOffice: '',
            branchOffices: [],
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved',
                    value: 'approve'
                }
            ],
            approve: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: '',
            headers: [
                {text: '', value: 'action', align: 'left', groupable: false, width: 50 },
                // , width: '150', groupable: false, width: '150'
                { text: 'Mill', value: 'mill_name', align: 'left'},
                { text: 'Office', value: 'office', align: 'left'},
                { text: 'Group', value: 'group_descr', align: 'left'},
                { text: 'PO ID', value: 'po_id', align: 'left' },
                // { text: 'STAT APRV', value: 'status_aprv', align: 'left', groupable: false, width: '150' },
                { text: 'Date Send Approve PO', value: 'dt_sendaprv', align: 'left'},
                { text: 'PIC', value: 'pic_id', align: 'left'},
                { text: 'Vendor', value: 'vendor_name', align: 'left'},
                { text: 'Stat', value: 'stat', align: 'left'},
                { text: 'Total Item', value: 'total_item', align: 'right'},
                { text: 'Approve date 1', value: 'dt_approve1', align: 'left'},
                { text: 'Approve date 2', value: 'dt_approve2', align: 'left'},
                // { text: 'Approve by', value: 'user_approve2', align: 'left'},
            ],
            headersColumn: {
                'Mill ID' : 'mill_id',
                'PO ID': 'po_id',
                'Doc ID': 'doc_id',
                'Item PO': 'po_item',
                'ProdCode': 'prod_code',
                'Item Desc': 'item_desc',
                'QTY': 'qty',
                'Wgt': 'wgt',
                'QTY RCV': 'qty_rcv',
                'Wgt RCV': 'wgt_rcv',
                'QTY Retur': 'qty_retur',
                'Wgt Retur': 'wgt_retur',
                'Unit Meas': 'unit_meas',
                'Unit Price': 'unit_price',
                'UM Price': 'um_price',
                'Qty PO Last': 'qty_last',
                'Price PO Last': 'unit_price_last',
                'Qty OH': 'total_qty_oh',
                'Date Req': 'dt_reqship',
                'Remark': 'remark',
                'PR ID': 'pr_id',
                'PR Item': 'pr_item',
            },
            searchHeader: '',
            data:[],
            
            dialogDetail:false,
            dialogPrId:'',

            detail_header: [
                { text: '', value: 'stat', align: 'left', groupable: false, width: 50 },
                { text: 'Item', value: 'po_item', align: 'left'},
                { text: 'ProdCode', value: 'prod_code', align: 'left'},
                { text: 'Item Desc', value: 'descr', align: 'left'},
                { text: 'Qty', value: 'qty', align: 'left'},
                { text: 'Weight', value: 'wgt', align: 'left'},
                { text: 'Unit Price', value: 'unit_price', align: 'right'},
                { text: 'Qty PO Last', value: 'qty_last', align: 'right'},
                { text: 'Price PO Last', value: 'unit_price_last', align: 'right'},
                { text: 'Unit Meas', value: 'unit_meas', align: 'left' },
                { text: 'Date Req', value: 'dt_reqship', align: 'left' },
                { text: 'Remark', value: 'remark', align: 'left' },
                { text: 'Pr Id', value: 'pr_id', align: 'left' },
                { text: 'Pr item', value: 'pr_item', align: 'left'}
            ],
            searchItem: '',
            dataDetail:[],

            dialogRejectMessage:false,
            rejectNoteDialog:'',
            rejectItemData:[],
            searchItemInv: '',
            inv_bal_header: [
                { text: 'Office ID', value: 'mill_id', align: 'left'},
                { text: 'ProdCode', value: 'prod_code', align: 'left'},
                { text: 'Batch ID', value: 'batch_id', align: 'left'},
                { text: 'WH ID', value: 'wh_id', align: 'left'},
                { text: 'Bin', value: 'bin_id', align: 'left'},
                { text: 'Qty Awal', value: 'qty_awal', align: 'right'},
                { text: 'Qty OH', value: 'qty_oh', align: 'right' },
                { text: 'Qty Rcv', value: 'qty_rcv', align: 'right'},
                { text: 'Qty Issue', value: 'qty_iss', align: 'right'},
                { text: 'Qty IA', value: 'qty_ia', align: 'right'},
                { text: 'Wgt Awal', value: 'wgt_awal', align: 'right'},
                { text: 'Wgt OH', value: 'wgt_oh', align: 'right' },
                { text: 'Wgt Rcv', value: 'wgt_rcv', align: 'right'},
                { text: 'Wgt Issue', value: 'wgt_iss', align: 'right'},
                { text: 'Wgt IA', value: 'wgt_ia', align: 'right'},
            ],
            inv_bals: [],
            inv_bal: null,
            loading_inv_bal: false,
            dialogMill:'',
            dialogDtApprovePo: '',
        }
    },
    async mounted() {
        this.$store.dispatch('setOverlay', true)
        await this.getGroupRight()
        await this.getData()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        close() {
            this.dialog = false
            this.text_dialog = ''
            // this.clear()
        },
        tr_datatable() {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear() {
            this.mill = ''
            this.division_id = ''
            this.requested_by = ''
            this.request_date = new Date()
        },
        async getGroupRight() {
            var reqBody = {
                "tr_type": this.trType,
                "aprv_level": this.aprvLevel,
                "aprv_user": `${this.$store.getters.Idemploye}`
            }
            var respData = await KkaApproval.fetchGroupRight('', reqBody)
            respData.data.data.forEach(item => {
                this.groupIds.push(item.group_id);
                this.officeIds.push(item.office_id);
                this.offGrps.push(item.off_grp);
            });
            await this.getbranchOffice()
                console.log(this.groupIds)
                console.log(this.officeIds)
                console.log(this.offGrps)
        },
        async getbranchOffice() {
            var reqBody = {
                "office": this.officeIds
            }
            var respData = await KkaApproval.fetchBranchOfficeFilter('', reqBody)
            if (respData.status === 200) {
                this.branchOffices = respData.data.data
            }

        },
        async getData(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                "tr_type": this.trType,
                "aprv_level": this.aprvLevel,
                "aprv_user": `${this.$store.getters.Idemploye}`,
                "group_id": this.groupIds,
                "approve_num": this.appNum
            }
            this.data=[]
            var respData = await KkaApproval.fetchDataPo('', reqBody)
            if (respData.status === 200) {
                this.data = respData.data.data
                // console.log(this.data)
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async getDetail(event){
            this.$store.dispatch('setOverlay', true)
            // console.log(event)
            var reqBody = {
                "mill_id": event.mill_id,
                "po_id": event.po_id
            }
            var respData = await KkaApproval.fetchDataDetailPO('', reqBody)
            
            if (respData.status === 200) {
                this.dataDetail = respData.data.data
                console.log(this.data)
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async setApprove(event){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                "mill_id": event.mill_id,
                "po_id": event.po_id,
                "approve_num": this.appNum,
                "user_approve": `${this.$store.getters.Idemploye}`
            }
            var respDataApprove = await KkaApproval.approvePo('', reqBody)
            if (respDataApprove.status === 200) {
                await this.getData()
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "success",
                    icon: "mdi-check",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Approve success",
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async setApproveDetail(event){
            this.$store.dispatch('setOverlay', true)
            // TODO
            var reqBody = {
                "mill_id": this.dialogMill,
                "po_id": this.dialogPrId,
                "approve_num": this.appNum,
                "user_approve": `${this.$store.getters.Idemploye}`
            }
            var respDataApprove = await KkaApproval.approvePo('', reqBody)
            if (respDataApprove.status === 200) {
                await this.getData()
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "success",
                    icon: "mdi-check",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Approve success",
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
            }
            this.dialogDetail = false
        },
        async setUnApprove(event){
            var reqBody = {
                "mill_id": event.mill_id,
                "po_id": event.po_id,
                "approve_num": this.appNum,
                "user_approve": `${this.$store.getters.Idemploye}`
            }
            await this.unApprove(reqBody)
        },
        async setUnApprove3(event){
            var reqBody = {
                "mill_id": event.mill_id,
                "po_id": event.po_id,
                "approve_num": "3",
                "user_approve": `${this.$store.getters.Idemploye}`
            }
            await this.unApprove(reqBody)
        },
        async unApprove(reqBody){
            this.$store.dispatch('setOverlay', true)
            var respDataApprove = await KkaApproval.unapprovePo('', reqBody)
            if (respDataApprove.status === 200) {
                await this.getData()
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "success",
                    icon: "mdi-check",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Unapprove success",
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
                // console.log(respDataApprove.data.data)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-check",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: respDataApprove.data.message,
                    text: respDataApprove.data.data,
                    visible: true
                };
            }
        },
        rejectDialog(event){
            this.dialogRejectMessage = true
            this.rejectItemData=event
        },
        async rejectItem(event){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {}
            if(this.rejectItemData.stat === 'X'){
                reqBody = {
                    "mill_id": this.rejectItemData.mill_id,
                    "po_id": this.rejectItemData.po_id,
                    "po_item": this.rejectItemData.po_item,
                    "prod_code": this.rejectItemData.prod_code,
                    "remark":this.rejectNoteDialog,
                    "stat": 'O'
                }
            } else if(this.rejectItemData.stat === 'O'){
                reqBody = {
                    "mill_id": this.rejectItemData.mill_id,
                    "po_id": this.rejectItemData.po_id,
                    "po_item": this.rejectItemData.po_item,
                    "prod_code": this.rejectItemData.prod_code,
                    "remark":this.rejectNoteDialog,
                    "stat": 'X'
                }
            }
            var respDataApprove = await KkaApproval.rejectPo('', reqBody)
            if (respDataApprove.status === 200) {
                console.log(event)
                await this.getDetail(this.rejectItemData)
                this.$store.dispatch('setOverlay', false)
                if(this.rejectItemData.stat === 'X'){
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-check",
                        mode: "multi-line",
                        position: "top",    
                        timeout: 7500,
                        title: "Warning",
                        text: "Reaccept item success",
                        visible: true
                    };
                } else if(this.rejectItemData.stat === 'O'){
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-check",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: "Reject item success",
                        visible: true
                    };
                }
                this.dialogRejectMessage = false
                this.rejectNoteDialog =''
                if(respDataApprove.data.data === 'reset'){
                    this.dialogDetail = false
                    this.getData()
                }
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-check",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: respDataApprove.data.message,
                    text: respDataApprove.data.data,
                    visible: true
                }
            }
        },
        async showDetailItem(event){
            console.log(event)
            await this.getDetail(event)
            this.dialogPrId = event.po_id.trim()
            this.dialogMill = event.mill_id.trim()
            this.dialogDtApprovePo = event.dt_approve1
            this.dialogDetail = true
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async exportExcel(){
            var reqBody = {
                "tr_type": this.trType,
                "aprv_level": this.aprvLevel,
                "aprv_user": `${this.$store.getters.Idemploye}`,
                "group_id": this.groupIds,
                "approve_num": this.appNum
            }
            var respData = await KkaApproval.exportDetailPO('', reqBody)
            if (respData.status === 200) {
                return respData.data.data
            }
        },
        tr_datatable_outstanding(item) {
            var rowClass = 'tr_datatable';
            if (this.inv_bal) {
                if(this.inv_bal.prod_code === item.prod_code && this.inv_bal.office_id === item.office_id && this.inv_bal.po_item === item.po_item){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            } else {
                rowClass = 'tr_datatable'
            }
            return rowClass;
        },
        async showInvBal(row){
            this.inv_bal = row
            this.inv_bals = []
            this.loading_inv_bal = true
            var reqBody = {
                "office_id": row.office_id,
                "prod_code": row.prod_code.trim()
            }
            var respData = await KkaApproval.getInvBal('', reqBody)
            
            if (respData.status === 200) {
                this.inv_bals = respData.data.data
                this.loading_inv_bal = false

            } else {
                this.loading_inv_bal = false

            }
        }
        
    },

}
</script>